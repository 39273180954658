<template>
  <div class="base-info">
    <Title :icon="icon" title="账款详情"> </Title>
    <div style="padding: 12px">
      <a-row type="flex" align="middle">
        <a-col :span="18">
          <a-descriptions bordered :column="3">

            <a-descriptions-item label="业务内容" :span="3">
              {{ invoice.business }}
            </a-descriptions-item>

            <a-descriptions-item label="发票号码">
              {{ invoice.invoiceCode }}
            </a-descriptions-item>


            <a-descriptions-item label="设计编号" :span='2'>
              {{ detail.designCode }}
            </a-descriptions-item>



            <a-descriptions-item label="发票类型">
              <DataDictFinder dictType="invoiceType" :dictValue="invoice.type" />
            </a-descriptions-item>

            <a-descriptions-item label="设计负责人">
              <div class="left">
                <span style="margin-right: 4px">
                  {{ invoice.liquidatorName }}
                </span>
                <EmployeeSelector @change="(arr) => {
                  if (arr.length) {
                    changeUser('liquidator', arr[0]);
                  }
                }
                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>
            </a-descriptions-item>
            <a-descriptions-item label="生产部门">
              {{ invoice.liquidatorDeptName }}
              <EditDept v-if="$getPermission($route.path + '/edit')" :detail="detail" @refresh="$emit('refresh')" />
            </a-descriptions-item>


            <a-descriptions-item label="发票状态">
              <DataDictFinder dictType="invoiceStatus" :dictValue="invoice.status" iconType="badge" />
            </a-descriptions-item>

            <a-descriptions-item label="区域负责人">
              <div class="left">
                <span style="margin-right: 4px">
                  {{ invoice.areaMasterName }}
                </span>

                <EmployeeSelector @change="(arr) => {
                  if (arr.length) {
                    changeUser('areaMaster', arr[0]);
                  }
                }
                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>
            </a-descriptions-item>
            <a-descriptions-item label="区域">
              {{ invoice.areaName }}
            </a-descriptions-item>

            <a-descriptions-item label="开票日期">
              {{ invoice.invoiceDate }}
            </a-descriptions-item>

            <a-descriptions-item label="现场负责人">
              <div class="left">
                <span style="margin-right: 4px">
                  {{ detail.siteLeaderName }}
                </span>
                <EmployeeSelector @change="(arr) => {
                  if (arr.length) {
                    changeUser('siteLeader', arr[0]);
                  }
                }
                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>

            </a-descriptions-item>

            <a-descriptions-item label="分管院长">

              <div class="left">
                <span style="margin-right: 4px">
                  {{ detail.chargeMasterName }}
                </span>
                <EmployeeSelector @change="(arr) => {
                  if (arr.length) {
                    changeUser('chargeMaster', arr[0]);
                  }
                }
                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>

            </a-descriptions-item>

            <a-descriptions-item label="账期">
              {{ detail.accountPeriod }}天
            </a-descriptions-item>
            <a-descriptions-item label="主要负责" :span="2">
              <div class="left">
                <span v-if="detail.masterId === invoice.liquidatorId" style="margin-right: 4px">
                  {{ invoice.liquidatorName }}
                </span>
                <span v-if="detail.masterId === invoice.areaMasterId" style="margin-right: 4px">
                  {{ invoice.areaMasterName }}
                </span>

                <EditMaster v-if="$getPermission($route.path + '/edit')" :detail="detail" @refresh="$emit('refresh')" />
              </div>
            </a-descriptions-item>

            <a-descriptions-item label="欠款单位">
              {{ invoice.buyerUnitName }}
            </a-descriptions-item>
            <a-descriptions-item label="申请人">
              {{ invoice.operatorName }}
            </a-descriptions-item>
            <a-descriptions-item label="申请开票部门">
              {{ invoice.operatorDeptName }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="6">
          <div class="info">
            <div class="statistic-wrapper">
              <div class="statistic">
                <div>
                  <span class="value"> {{ invoice.itemsPrice }} </span>
                  <span class="unit">元</span>
                </div>
                <div class="label">不含税收入</div>
              </div>
              <div class="statistic">
                <div>
                  <span class="value"> {{ invoice.itemsTax }} </span>
                  <span class="unit">元</span>
                </div>
                <div class="label">税额</div>
              </div>
              <div class="statistic">
                <div>
                  <span class="value"> {{ invoice.totalPriceLowercase }} </span>
                  <span class="unit">元</span>
                </div>
                <div class="label">发票金额</div>
              </div>
              <div class="statistic">
                <div>
                  <span class="value"> {{ detail.sumRemaining }} </span>
                  <span class="unit">元</span>
                </div>
                <div class="label">应收余额</div>
              </div>
            </div>
            <a-progress stroke-linecap="square" :percent="Math.round(
              (detail.sumReceivedThisyear /
                (detail.sumReceivedThisyear + detail.sumRemainingThisday)) *
              100
            )
              " :strokeWidth="16" />

            <div class="footer">
              <div class="item">
                本年回款额：{{ detail.sumReceivedThisyear }}元
              </div>
              <div class="item">
                当日余额：{{ detail.sumRemainingThisday }}元
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import EmployeeSelector from "@/components/employee-selector";
import EditMaster from "./edit-master.vue";
import { edit } from "@/api/finance/accounts-receivable";
import EditDept from "./edit-dept.vue";
export default {
  components: {
    Title,
    EmployeeSelector,
    EditMaster,
    EditDept
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      icon: require("@/assets/accounts-receivable/icon-1.png"),
    };
  },

  computed: {
    invoice() {
      return this.detail.invoice ? this.detail.invoice : {};
    },
  },

  methods: {
    changeUser(type, user) {
      console.log(type, user);
      const data = {
        id: this.detail.id,
        invoice: {
          id: this.detail.invoice.id,
        },
      };
      if (type === "liquidator") {
        data.invoice = {
          ...data.invoice,
          liquidatorDeptId: user.deptId,
          liquidatorDeptName: user.deptUniqueName,
          liquidatorId: user.userId,
          liquidatorName: user.name,
        };
      } else if (type === "areaMaster") {
        data.invoice = {
          ...data.invoice,
          areaId: user.deptId,
          areaName: user.deptUniqueName,
          areaMasterId: user.userId,
          areaMasterName: user.name,
        };
      } else if (type === 'chargeMaster') {
        data.chargeMaster = user.userId;
        data.chargeMasterName = user.name;

      } else if (type === 'siteLeader') {
        data.siteLeader = user.userId;
        data.siteLeaderName = user.name
      }
      edit(data).then(() => {
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.base-info {
  background-color: #fff;
  margin: 8px;
}

.info {
  padding-left: 20px;
}

.statistic-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 24px;

  .statistic {
    background: #f4f4f6;
    border-radius: 5px;
    padding: 14px;

    .value {
      font-size: 16px;
      font-weight: bold;
      color: #1890ff;
    }

    .label {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 4px;
    }
  }
}

.footer {
  display: flex;
  margin-top: 24px;
  padding-left: 20px;

  .item {
    flex: 1;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      left: -20px;
      height: 12px;
      width: 12px;
      background-color: #1890ff;
      border-radius: 1px;
    }

    &:last-child {
      margin-left: 40px;
    }

    &:last-child::after {
      background-color: rgb(245, 245, 245);
    }
  }
}
</style>