<template>
  <div>
    <Pane />

    <BaseInfo :detail="detail" @refresh="getData" />

    <template v-if="id">
          <List
          :id="id"
          @refresh="getData"
          v-if="$getPermission($route.path + '/payment-list')"
        />

    <div class="container">
            <Plan
          :detail="detail"
          :list="
            Array.isArray(detail.collectList)
              ? detail.collectList
              : []
          "
          @refresh="getData"
        />
        <Add :id='id' :detail='detail' :list="  Array.isArray(detail.collectList)
              ? detail.collectList
              : []"   @refresh="getData" />
    </div>

    </template>


  </div>
</template>

<script>
import BaseInfo from "./components/base-info";
import List from "./components/list";
import Plan from "./components/plan";
import Add from "./components/add";

import { fetchDetail } from "@/api/finance/accounts-receivable";

export default {
  components: {
    BaseInfo,
    List,
    Plan,
    Add,
  },

  data() {
    return {
      id: null,
      detail: {},
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    this.id = id;
    this.getData();
  },

  methods: {
    getData() {
      fetchDetail({ id: this.id }).then((res) => {
        if (res) {
          this.detail = res;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 8px;
  & > div {
    background-color: #fff;
  }
}
</style>