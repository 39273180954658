<template>
  <div class="list">
    <Title :icon="icon" title="回款记录"> </Title>
    <div style="padding: 12px">
      <div class="between">
        <a-date-picker
          mode="year"
          placeholder="回款年度"
          @panelChange="panelChange"
          :value="year"
          format="YYYY"
          :open="open"
          @openChange="openChange"
          style="width: 240px"
        />

        <a-button
          type="primary"
          v-if="$getPermission($route.path + '/add-payment')"
          @click="visible = true"
          >新增回款</a-button
        >
      </div>
      <Padding />

      <a-table
        :data-source="list"
        :loading="loading"
        :pagination="false"
        bordered
      >
        <a-table-column title="回款时间" data-index="createAt" align="center" />
        <a-table-column title="回款年度" data-index="year" align="center" />
        <a-table-column title="回款额(元)" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.account }}</span>
          </template>
        </a-table-column>
        <a-table-column title="操作" align="right">
          <template slot-scope="text">
            <a
              href="#"
              class="danger"
              v-if="$getPermission($route.path + '/delete-payment')"
              @click.prevent="removeText(text)"
              >删除</a
            >
          </template>
        </a-table-column>
      </a-table>

      <a-modal
        :visible="visible"
        title="新增回款"
        :footer="null"
        @cancel="cancel"
      >
        <a-form :form="form" :colon="false" @submit="handleSubmit">
          <a-form-item label="回款时间">
            <a-date-picker
              placeholder=""
              v-decorator="[
                'createAt',
                { rules: [{ required: true, message: '请选择！' }] },
              ]"
              style="width: 100%"
            />
          </a-form-item>
          <a-form-item label="回款金额（元）">
            <a-input-number
              :formatter="$inputNumberFormat"
              placeholder=""
              v-decorator="[
                'account',
                { rules: [{ required: true, message: '请输入！' }] },
              ]"
              style="width: 100%"
              :min="0.01"
            />
          </a-form-item>
          <div class="right">
            <a-space>
              <a-button @click="cancel">取消</a-button>
              <a-button type="primary" html-type="submit" :loading="saveLoading"
                >提交</a-button
              >
            </a-space>
          </div>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import {
  fetchPaymentList,
  addPayment,
  deletePayment,
} from "@/api/finance/accounts-receivable";
export default {
  components: {
    Title,
  },

  props: {
    id: {
      type: String,
    },
  },

  data() {
    return {
      icon: require("@/assets/accounts-receivable/icon-2.png"),

      year: null,
      open: false,
      loading: false,
      list: [],

      visible: false,
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  watch: {
    year() {
      this.getList();
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    panelChange(value) {
      this.year = value;
      this.open = false;
    },
    openChange(value) {
      this.open = value;
    },

    getList() {
      this.loading = true;
      fetchPaymentList({
        year: this.year ? this.year.format("YYYY") : undefined,
        pid: this.id,
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          addPayment({
            ...values,
            createAt: values.createAt.format("YYYY-MM-DD"),
            pid: this.id,
          })
            .then(() => {
              this.form.resetFields();
              this.cancel();
              this.getList();
              this.$emit("refresh");
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },

    removeText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          deletePayment({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  background-color: #fff;
  margin: 8px;
}
.info {
  padding-right: 56px;
}

.category {
  height: 100%;
  display: flex;
  align-items: center;
}

.divider {
  height: 200px;
}

.statistic-wrapper {
  flex: 1;
  padding: 0 20px;

  .statistic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .label {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.progress {
  padding: 0 20px;
  .title {
    font-size: 14px;
    color: #333;
    margin-bottom: 16px;
  }
  .unit {
    font-size: 12px;
    margin-left: 2px;
  }
}
</style>