var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Title',{attrs:{"icon":_vm.icon,"title":"添加执行动态"}}),_c('div',{staticStyle:{"padding":"12px"}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"执行动态"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'content',
          { rules: [{ required: true, message: '请输入！' }] },
        ]),expression:"[\n          'content',\n          { rules: [{ required: true, message: '请输入！' }] },\n        ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1),_c('a-form-item',{attrs:{"label":"邀请协助"}},[_c('EmployeeSelector',{attrs:{"single":false,"value":_vm.helperList},on:{"change":(arr) => {
          if (arr.length > 0) {
            _vm.helperList = arr;
          } else {
            _vm.helperList = []
          }
        }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v(" 选择 ")])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.helperList,"pagination":false}},[_c('a-table-column',{attrs:{"title":"部门","data-index":"deptUniqueName"}}),_c('a-table-column',{attrs:{"title":"姓名","data-index":"name"}})],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.setEmpty}},[_vm._v("清空")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("添加动态")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }