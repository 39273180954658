<template>
  <div>
    <Title :icon="icon" title="添加执行动态" />

    <div style="padding: 12px">
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-form-item label="执行动态">
          <a-textarea v-decorator="[
            'content',
            { rules: [{ required: true, message: '请输入！' }] },
          ]" :auto-size="{ minRows: 3 }" />
        </a-form-item>

        <a-form-item label="邀请协助">

          <EmployeeSelector :single="false" @change="(arr) => {
            if (arr.length > 0) {
              helperList = arr;
            } else {
              helperList = []
            }
          }
            " :value="helperList">
            <a-button type="primary" size="small">
              选择
            </a-button>
          </EmployeeSelector>

          <Padding />

          <a-table bordered :dataSource="helperList" :pagination="false">
            <a-table-column title="部门" data-index="deptUniqueName">
            </a-table-column>
            <a-table-column title="姓名" data-index="name">
            </a-table-column>
          </a-table>

        </a-form-item>

        <div class="center">
          <a-space>
            <a-button @click="setEmpty">清空</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading">添加动态</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import { fill, } from "@/api/finance/accounts-receivable";
// fetchHelperList

import EmployeeSelector from "@/components/employee-selector";

import { mapState } from "vuex";
export default {
  components: {
    Title,
    EmployeeSelector,
  },

  props: {
    id: {
      // 应收账款 id
      type: String,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    list: {
      // 这个应收账款下清收计划列表
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      icon: require("@/assets/accounts-receivable/icon-4.png"),
      form: this.$form.createForm(this),
      helperList: [],
      saveLoading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    disabled() {
      if (this.$getPermission(this.$route.path + "/report")) {
        return false;
      }
      if (!this.detail.invoice) {
        return true;
      }
      if (
        this.user.uuid === this.detail.invoice.liquidatorId ||
        this.user.uuid === this.detail.invoice.areaMasterId
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    setEmpty() {
      this.form.resetFields();
      this.helperList = []
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          if (!this.list.length) {
            this.$message.error("暂无清收计划！");
            return;
          }


          this.saveLoading = true;
          fill({
            content: values.content,
            helperList: this.helperList.map(item => {
              return {
                fillerId: item.userId,
                fillerName: item.name,
              }
            }),
            pid: this.list[this.list.length - 1].id,
          })
            .then(() => {
              this.setEmpty();
              this.$emit("refresh");
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>
