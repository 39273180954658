<template>
  <div>
    <a-button size="large" type="primary" @click="visible = true" class="left">
      <span style="margin-right: 12px">
        点击填报清收计划@{{ item.userName }}
      </span>
      <div class="tag">
        <DataDictFinder
          dictType="account_collect_status"
          :dictValue="item.status"
        />
      </div>
    </a-button>

    <a-modal
      title="填报清收计划"
      :visible="visible"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-form-item label="清收计划">
          <a-textarea
            v-decorator="[
              'plan',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
            :auto-size="{ minRows: 3 }"
          />
        </a-form-item>

        <a-form-item label="清收时间">
          <a-date-picker
            placeholder=""
            :disabled-date="disabledDate"
            v-decorator="[
              'fillDate',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="清收金额（元）">
          <a-input-number
            :formatter="$inputNumberFormat"
            placeholder=""
            v-decorator="[
              'sum',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
            style="width: 100%"
            :min="0.01"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { addPlan } from "@/api/finance/accounts-receivable";
import moment from "moment";
export default {
  props: {
    pid: {
      type: String,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  methods: {
    disabledDate(current) {
      return (
        (current && current < moment().endOf("day")) ||
        (current && current > moment().add(1, "years").endOf("day"))
      );
    },

    cancel() {
      this.visible = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          addPlan({
            ...values,
            fillDate: values.fillDate.format("YYYY-MM-DD"),
            pid: this.pid,
            id: this.item.id,
          })
            .then(() => {
              this.form.resetFields();
              this.cancel();
              this.$emit("refresh");
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tag {
  display: inline-block;
  padding: 4px 8px;
  background: #ff8000;
  text-align: center;
  opacity: 1;
  border-radius: 2px;
  color: #fff;
}
</style>