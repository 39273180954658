<template>
  <div>
    <a href="#" @click.prevent="visible = true">修改</a>
    <a-modal title="修改生产部门" :visible="visible" @cancel="cancel" @ok="ok">
      <a-select v-model="selected"
                show-search
                :filter-option="$selectFilterOption"
                style="width: 200px;"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
    </a-modal>
  </div>
</template>
<script>
import { edit } from "@/api/finance/accounts-receivable";
import organization from "@/mixins/organization";
export default {
  mixins: [organization],
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selected: null,
      visible: false,
    };
  },

  watch: {
    detail() {
      this.selected = this.detail.invoice?.liquidatorDeptId;
    },
  },

  methods: {
    cancel() {
      this.visible = false;
    },
    ok() {
      const data = {
        ...this.detail,
      };
      const obj = this.organizationList.find((item) => item.id === this.selected);
      data.invoice.liquidatorDeptId = this.selected;
      data.invoice.liquidatorDeptName = obj.uniqueName;
      edit(data).then(() => {
        this.$emit("refresh");
        this.visible = false;
      });
    },
  },
};
</script>