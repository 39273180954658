<template>
  <div>
    <a href="#" @click.prevent="visible = true">修改</a>
    <a-modal title="修改主要负责" :visible="visible" @cancel="cancel" @ok="ok">
      <a-radio-group v-model="selected">
        <a-radio value="liquidator">设计负责人</a-radio>
        <a-radio value="areaMaster">区域负责人</a-radio>
      </a-radio-group>
    </a-modal>
  </div>
</template>
<script>
import { edit } from "@/api/finance/accounts-receivable";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selected: null,
      visible: false,
    };
  },

  watch: {
    detail() {
      if (this.detail.masterId === this.detail.invoice?.liquidatorId) {
        this.selected = "liquidator";
      }
      if (this.detail.masterId === this.detail.invoice?.areaMasterId) {
        this.selected = "areaMaster";
      }
    },
  },

  methods: {
    cancel() {
      this.visible = false;
    },
    ok() {
      const data = {
        ...this.detail,
      };

      if (this.selected === "liquidator") {
        data.masterId = this.detail.invoice?.liquidatorId;
      } else if (this.selected === "areaMaster") {
        data.masterId = this.detail.invoice?.areaMasterId;
      }
      edit(data).then(() => {
        this.$emit("refresh");
        this.visible = false;
      });
    },
  },
};
</script>